<template>
    <div v-if="blog && Object.keys(blog).length > 0">
        <section
            id="section-history"
            data-aos="fade-in"
            data-aos-duration="1000"
        >
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 equalHW padding0">
                        <div
                            class="history-image"
                            v-bind:style="
                                'background: url(' +
                                    blog.main_image +
                                    ') no-repeat top center #191b1b;background-size:cover;'
                            "
                        ></div>
                    </div>
                    <div class="col-lg-6 equalHW padding0">
                        <div class="section specialities">
                            <div class="section history">
                                <div class="history-content alignVCenter">
                                    <div class="container-col">
                                        <!--<h4 class="small medium right light">Discover your taste</h4>-->
                                        <h4 class="white title">
                                            {{ blog.title }}
                                        </h4>
                                        <div class="voffset-30"></div>
                                        <p v-html="blog.lead"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="voffset-131"></div>
            <div class="container" style="color: white;">
                <div class="row">
                    <div class="col-12" v-html="blog.content"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Article",
    data() {
        return {
            blog: {},
            others: []
        };
    },
    beforeMount() {
        this.getBlog();
    },
    watch: {
        $route: {
            handler: function() {
                this.getBlog();
            },
            deep: true
        }
    },
    computed: {
        url: function() {
            return `${process.env.VUE_APP_API_URL}`;
        },
        shareFb: function() {
            return (
                "http://www.facebook.com/share.php?u=" + document.location.href
            );
        },
        shareTw: function() {
            return (
                "http://twitter.com/share?text=Névmatrica&url=" +
                document.location.href +
                "&hashtags=sticker"
            );
        },
        sharePin: function() {
            return (
                "https://hu.pinterest.com/pin/create/link/?url=" +
                document.location.href
            );
        }
    },
    methods: {
        getBlog() {
            this.$http
                .get(`${this.url}/blog/get/${this.$route.params.slug}`)
                .then(response => {
                    this.blog = response.data.data;
                    this.others = response.data.others;
                });
        }
    }
};
</script>

<style scoped></style>
